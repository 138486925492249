import React from "react";
//styled components library
import styled from "styled-components";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Button from "../../components/CustomButtons/Button";
import { Link } from "react-router-dom";

// images
import tshirt from "assets/img/polo-shirt.png";
import sweater from "assets/img/sweater.png";
import dress from "assets/img/cocktail-dress.png";

import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle";

const HighLightContainer = styled.div`
  &:hover {
    background-color: #00acc1;
    box-shadow: ;
  }
`;

const ImgContainer = styled.img`
  height: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  min-width: 164px;
  max-width: 175px;

  &:hover {
    height: 155px;
    width: 65%;
  }
`;

interface Props {
  classes: any;
}

interface State {
  value: number;
}

class Dashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
  }
  handleChange = (event: any, value: number) => {
    this.setState({ value });
  };

  handleChangeIndex = (index: number) => {
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer /* style={{ height: "10vh" }} */ />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CardHeader color="white" style={{ marginTop: "10vh" }}>
              <h3 className={classes.cardTitleBlack}>
                {" "}
                Welcome to your YT account!
              </h3>
              <h4
                className={classes.cardCategoryBlack}
                style={{ textAlign: "center" }}
              >
                Select a design and start creating.
              </h4>
            </CardHeader>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <HighLightContainer>
              <Card /* style={{ maxWidth: "310px" }}  */ chart={true}>
                <Link to="/admin/results/dresses">
                  <CardHeader style={{ backgroundColor: "white" }}>
                    <div className="ct-chart">
                      <ImgContainer src={dress} />
                    </div>
                  </CardHeader>
                  <CardBody style={{ textAlign: "center" }}>
                    {/* <hr style={{ color: "darkGray" }} />
                    <Button color="loadMoreButton">
                      {" "}
                      Dresses{" "}
                      <h4 className={classes.cardTitle}>Dresses</h4>{" "}
                    </Button> */}
                  </CardBody>
                </Link>
              </Card>
            </HighLightContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart={true}>
              <Link to="/admin/results/sweaters">
                <CardHeader style={{ backgroundColor: "white" }}>
                  <div className="ct-chart" />
                  <ImgContainer src={sweater} />
                </CardHeader>
                <CardBody style={{ textAlign: "center" }}>
                  {" "}
                  {/* <hr style={{ color: "darkGray" }} />
                  <Button color="loadMoreButton">
                    {" "}
                    Sweaters{" "}
                    <h4 className={classes.cardTitle}>Sweaters</h4>{" "}
                  </Button> */}
                </CardBody>
              </Link>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart={true}>
              <Link to="/admin/results/tshirt_polo">
                <CardHeader style={{ backgroundColor: "white" }}>
                  <div className="ct-chart">
                    <ImgContainer src={tshirt} />
                  </div>
                </CardHeader>
                <CardBody style={{ textAlign: "center" }}>
                  {/* <hr style={{ color: "darkGray" }} />
                  <Button color="loadMoreButton">
                    {" "}
                    T-shirts
                    <h4 className={classes.cardTitle}>T-shirts</h4>
                  </Button> */}
                </CardBody>
              </Link>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// Dashboard.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default withStyles(dashboardStyle)(Dashboard);
