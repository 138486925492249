import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useAuth0 } from "./auth/auth0-context";
import { Helmet } from "react-helmet";

// core components
import Admin from "./layouts/Admin";
import "assets/css/material-dashboard-react.css?v=1.6.0";
import PageSpinner from "./components/Spinners/PageSpinner";

function App(): JSX.Element {
  const { isAuthenticated, isLoading, user, loginWithRedirect } = useAuth0();

  useEffect(() => {
    !isLoading && !user && loginWithRedirect();
  }, [isLoading, user, loginWithRedirect]);

  return (
    <div className="App">
      <Helmet>
        <title>Yoona Technology</title>
        <meta
          name="description"
          content="Yoona Technology - making the design process easier than ever before! Generate unlimited designs in just a few clicks with our AI neural network. Leverage the full potential of your own visual data: whenever you need a single design, color concept or even collection, the YOONA design app is able to produce it in just a few minutes."
        />
      </Helmet>
      <div className={"container"}>
        <Switch>
          {isLoading ? <PageSpinner /> : null}
          {isAuthenticated ? <Route path="/admin" component={Admin} /> : null}
          <Route
            path={"/"}
            exact={true}
            render={() =>
              isAuthenticated ? <Redirect to="/admin/home" /> : null
            }
          />
        </Switch>
      </div>
    </div>
  );
}
export default App;
