import React from "react";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import Spinner from "./Spinners/Spinner";
import styled from "styled-components";

// @material-ui/core
import withStyles, {
  StyledComponentProps,
} from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../assets/jss/material-dashboard-react/views/dashboardStyle";
import GridItem from "./Grid/GridItem";
import GridContainer from "./Grid/GridContainer";
import CardHeader from "./Card/CardHeader";
import Card from "./Card/Card";
import Button from "./CustomButtons/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: inherit;

  > img {
    width: 100%;
    height: auto;
  }
`;

const IconButton = styled.button`
  position: absolute;
  top: 10%;
  left: 85%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: transparent;
  color: black;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;

  &:hover {
    color: #00acc1;
    font-size: 40px;
  }
`;

const IconButton2 = styled.button`
  position: absolute;
  top: 15%;
  left: 85%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: transparent;
  color: black;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;

  &:hover {
    color: 'red';
    font-size: 30px;
  }
`;

const IMAGE_GENERATION_LAMBDA_URL =
  "https://vdiszt7i5m.execute-api.us-east-2.amazonaws.com/dev/generate_image";

const convertToImageUrl = (img: string) => {
  const parts = img.split("/");
  const imgName = parts[parts.length - 1];
  return `https://yoona-mvp.s3.us-east-2.amazonaws.com/generated-images/${imgName}`;
};

const fetchImages = async (modelId: string) => {
  const { data } = await axios.get<string[]>(
    `${IMAGE_GENERATION_LAMBDA_URL}?model=${modelId || "dresses"}`
  );
  return data.map(convertToImageUrl);
};

const ResultsContainer = (
  props: RouteComponentProps & { modelId: string } & StyledComponentProps
) => {
  const [images, setImages] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [needsLoading, setNeedsLoading] = React.useState(true);

  React.useEffect(() => {
    const handleLoadPage = async () => {
      try {
        setIsLoading(true);
        const newImages = await fetchImages(props.modelId);
        setImages((currentImages) => [...currentImages, ...newImages]);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
        setNeedsLoading(false);
      }
    };
    if (needsLoading) {
      handleLoadPage();
    }
  }, [needsLoading, props.modelId]);

  const addFavourite = (img:string) => {
    axios
    .post("http://localhost:8000/api/designs/", {name: img})
    .then((res) => console.log(res.data))
    .catch((err) => console.log(err.response.data));
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CardHeader>
            {isLoading ? (
              <h3 className={props?.classes?.cardCategoryBlack}>
                {" "}
                Please wait, our AI is designing for you in real time...
              </h3>
            ) : (
              <>
                <h3 className={props?.classes?.cardTitleBlack}>Select designs</h3>
                <h4 className={props?.classes?.cardCategoryBlack}>
                  Click on your selected image to save your designs
                </h4>
              </>
            )}
          </CardHeader>
        </GridItem>
      </GridContainer>
      <GridContainer spacing={3}>
        {images.map((image) => (
          <GridItem xs={12} sm={6} md={3}>
          {console.log("image details",image)}
            <ImageContainer>
              <Card key={image}>
                <img src={image} alt="" style={{ width: "100%" }} />
                <a href={image} download>
                  <IconButton>
                    <CloudDownloadIcon />
                  </IconButton>
                  {/* <br/> */}
                  {/* <IconButton2>
                  <FavoriteBorderIcon onClick={() => addFavourite(image)}/>
                  </IconButton2> */}
                </a>
              </Card>
            </ImageContainer>
          </GridItem>
        ))}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} />
      </GridContainer>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              color="loadMoreButton"
              onClick={() => setNeedsLoading(true)}
            >
              LOAD MORE
            </Button>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withStyles(dashboardStyle)(ResultsContainer);
